@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.cardsContainer {
  margin: 2vw;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  gap: 6px;
  width: 100%;
  align-items: center;

  @media (--viewportMedium) {
    width: auto;
    min-width: 55vw;
  }

}

.title {
  color: black;
  font-size: 25px;
  margin: 0;
  padding: 10px;
}

.text {
  color: black;
  font-size: 18px;
  margin: 0;
}

.icon {
  scale: 1.6;
}

.card1 {
  display: flex;
  flex-direction: column;
  background-color: #E7F4EA;
  box-shadow: var(--boxShadow);
  border: solid 1px #ECF0EF;
  align-items: center;
  padding: 20px 20px;
  gap: 6px;
  min-width: 15vw;
  border-radius: 6px;
}

.card2 {
  display: flex;
  flex-direction: column;
  background-color: #CFF0D5;
  box-shadow: var(--boxShadow);
  border: solid 1px #ECF0EF;
  align-items: center;
  padding: 20px 20px;
  gap: 6px;
  min-width: 15vw;
  border-radius: 6px;
}

.card3 {
  display: flex;
  flex-direction: column;
  background-color: #B8EBC0;
  box-shadow: var(--boxShadow);
  border: solid 1px #ECF0EF;
  align-items: center;
  padding: 20px 20px;
  gap: 6px;
  min-width: 15vw;
  border-radius: 6px;
}
@import '../../../styles/customMediaQueries.css';

.root {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 10vw;
  align-items: center;
  gap: 20px;
}

.listingsContainer {
  display: grid;
  width: 100%;
  padding: 20px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
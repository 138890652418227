@import '../../../styles/customMediaQueries.css';

.root {
  background-image: url('./images/hero_background.jpg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vw;
  text-align: center;



}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.title {
  font-size: 36px;
  color: white;
  font-weight: 800;
}

.info {
  font-size: 22px;
  color: white;
  font-weight: 800;
}

.button {
  composes: buttonPrimary from global;
  max-width: 240px;
  margin-top: 50px;
  margin-bottom: 50px;
}